import * as React from 'react';
import { useTranslations } from '@mentimeter/i18n';
import { P, Heading } from '@mentimeter/ragnar-ui/typography';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';

interface PropsT {
  showSignup: boolean;
  setAuthMode: React.MouseEventHandler<HTMLButtonElement>;
}
const AuthModalHeader = ({ showSignup, setAuthMode }: PropsT) => {
  const t = useTranslations('authPackage');
  const headingText = showSignup ? t('create_free_account') : t('login');
  const switchText = showSignup
    ? t('already_have_account')
    : t('no_account_yet');
  const buttonText = showSignup ? t('login') : t('form_signup_button');
  return (
    <>
      <Box width={1} alignItems="center">
        <Heading textAlign="center">{headingText}</Heading>
      </Box>
      <Box flexDirection="row" alignItems="baseline">
        <P mt={1} mb={4}>
          {switchText}{' '}
        </P>
        <Button variant="subtle" href="#" onClick={setAuthMode} ml={1}>
          {buttonText}.
        </Button>
      </Box>
    </>
  );
};

export default AuthModalHeader;
