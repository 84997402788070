import * as React from 'react';
import { withMentimeterI18nProvider } from '@mentimeter/i18n';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalRoot,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@mentimeter/ragnar-ui/modal';
import type { SSOType } from '../../types';
import type AuthPackageT from '../../AuthPackage';
import AuthSwitch from './AuthSwitch';
import AuthModalHeader from './AuthModalHeader';

interface PropsT {
  openModal: boolean;
  setOpenModal: () => void;
  showSignup: boolean;
  authPackage: AuthPackageT;
  continueUrl: string | undefined;
  referral: string;
  useSocialRedirect?: boolean;
}

interface StateT {
  showSignup: boolean;
  openModal: boolean;
  SSOType: SSOType;
}

const getInitialState = (): StateT => {
  return {
    showSignup: true,
    openModal: false,
    SSOType: 'none',
  };
};

class AuthModal extends React.Component<PropsT, StateT> {
  override state = getInitialState();
  static getDerivedStateFromProps = (
    { openModal: propsShow, showSignup: propsShowSignup }: PropsT,
    { openModal: stateShow, showSignup: stateShowSignup }: StateT,
  ) => {
    let newState: Partial<StateT> = {
      openModal: propsShow,
    };
    // if modal goes from closed to open - make sure the property showSignup is ruling
    if (propsShow && !stateShow) {
      newState = { ...newState, showSignup: propsShowSignup };
    }
    // if modal goes from open to close - reset modal state
    if (!propsShow && stateShow) {
      return {
        ...getInitialState(),
        ...newState,
        // don't change state of showSignup on close, it will switch before modal has been closed
        // and look weird:
        showSignup: stateShowSignup,
      };
    }
    return newState;
  };
  override render() {
    const { showSignup, openModal } = this.state;
    const {
      referral,
      authPackage,
      setOpenModal,
      continueUrl,
      useSocialRedirect,
    } = this.props;
    return (
      <ModalRoot open={openModal} onOpenChange={setOpenModal}>
        <ModalContainer
          width={['100vw', '400px']}
          minHeight={showSignup ? '100%' : '0'}
          data-testid="auth-modal"
        >
          <ModalBody p="space2">
            <AuthSwitch
              authPackage={authPackage}
              showSignup={showSignup}
              continueUrl={continueUrl}
              referral={referral}
              useSocialRedirect={Boolean(useSocialRedirect)}
              render={({
                showSignup,
                renderAuth,
                toggleAuthMode,
                SSOMessageActive,
              }) => {
                return (
                  <>
                    {!SSOMessageActive && (
                      <ModalHeader alignItems="center">
                        <AuthModalHeader
                          showSignup={showSignup}
                          setAuthMode={toggleAuthMode}
                        />
                      </ModalHeader>
                    )}
                    {renderAuth()}
                    {!SSOMessageActive && showSignup && (
                      <ModalFooter justifyContent="center">
                        <Button
                          variant="subtle"
                          href={`/auth/saml${
                            continueUrl ? `?continue=${continueUrl}` : ''
                          }`}
                          mt={2}
                          size="compact"
                        >
                          Sign up with SSO
                        </Button>
                      </ModalFooter>
                    )}
                  </>
                );
              }}
            />
          </ModalBody>
        </ModalContainer>
      </ModalRoot>
    );
  }
}

export default withMentimeterI18nProvider(AuthModal);
